export const custom = {
  /**
  * Idiomas disponibles ['es','en','pt','cat']
  */
  availableLangs: ['es'],

  /**
   * Idioma por defecto: ['es']
   */
  defaultLang: 'es',

  /**
   * Versión de la API: v2|v3
   */
  apiVersion: 'v3',

  /**
   * API key de conexión a la api definida en cada entorno
   * (debe ser el mismo en todos los servicios)
   */
  apiKey: '3d5246c0-1f29-4806-aa97-f907e63d0f84',

  /**
   * URL de la app
   *
   * subdominio.dominio
   */
  appUrlDomain: 'appalarescontigo.alares',

  /**
  * Nombre de la app
  */
  appName: 'Alares Contigo',

  /**
  * Código de analytics 'G-XXXXXXXXXXX'
  * (Activado en pro, desactivado en pre y local)
  */
  analyticsId: 'G-0GZ1LDZ9V8',

  /** true o false. Define si es necesario hacer login para acceder a los contenidos */
  protected: true,

  /** Registro habilitado o deshabilitado */
  registration: false,

  /** true o false. Define si los formularios son para conciliación o no */
  conciliation: true
};
